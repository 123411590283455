import { computed } from 'vue';
<template>
    <div>
        <v-dialog
            max-width="800"
            v-model="showDialg">
            <!-- add close -->
            <div class="tw-flex tw-justify-end">
                <v-btn
                    class="tw-m-4"
                    icon
                    @click="emit('close')"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </div>

            <v-card class="tw-flex tw-items-center tw-justify-center tw-pt-12">
                <v-form
                    ref="form"
                    @submit.prevent="onSubmit"
                    class="auth-form">
                    <v-text-field
                        v-model.trim="mechanographicNumber"
                        class="auth-input"
                        :rules="[rules.required]"
                        type="text"
                        name="mechanographicNumber"
                        :placeholder="$t('fields.mechanographicNumber')"></v-text-field>
                    <div v-if="errorMessage">
                        <v-alert
                            type="error"
                            variant="tonal"
                            density="compact"
                            >{{ errorMessage }}</v-alert
                        >
                    </div>

                    <v-btn
                        :loading="isLoading"
                        type="submit"
                        color="primary"
                        class="auth-btn"
                        >{{ $t('auth.login') }}</v-btn
                    >
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup lang="ts">
    import { defineProps, computed, ref } from 'vue';
    import useRules from '@/composables/rules';
    import axios from '@axios';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useError } from '@/composables/useError';

    const authStore = useAuthStore();
    const $router = useRouter();
    const $t = useI18n().t;
    const $error = useError();

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);
    const mechanographicNumber = ref('');
    const errorMessage = ref('');

    const props = defineProps({
        dialog: Boolean,
    });

    const emit = defineEmits(['close']);

    const showDialg = computed({
        get: () => props.dialog,
        set: (value) => {
            if (value) {
                return value;
            }
        },
    });

    async function onSubmit() {
        if (!form.value) return;

        errorMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        isLoading.value = true;

        try {
            const { data } = await axios.post('/auth/PIN', {
                mechanographicNumber: mechanographicNumber.value
            });

            authStore.setToken(data.jwt);
            authStore.setUser(data.user);

            // emit to parent to close dialog
            emit('close');
        } catch (error) {
            errorMessage.value = $error.getErrorMessage(error, 'auth.errors');
        } finally {
            form.value.reset();
            isLoading.value = false;
        }
    }
</script>

<style scoped></style>
