<template>
    <div class="tw-flex tw-h-full tw-items-center tw-justify-center">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <div v-if="!isConfigured">
                        <p class="tw-text-center tw-text-xl tw-font-bold">{{ $t('setup.label1') }}</p>
                        <v-card
                            class="mx-auto my-8"
                            max-width="600">
                            <v-card-item>
                                <div class="">
                                    <v-form
                                        class="tw-w-full tw-flex-col tw-justify-center"
                                        ref="form"
                                        @submit.prevent="onSubmit">
                                        <v-text-field
                                            class="auth-input"
                                            type="text"
                                            v-model="code"
                                            :rules="[rules.required]"
                                            name="text"
                                            :placeholder="$t('setup.code')"></v-text-field>

                                        <v-btn
                                            :loading="false"
                                            type="submit"
                                            :disabled="isDisabled"
                                            class="tw-bg-primary-500 tw-text-white tw-mx-auto tw-my-4 tw-h-auto tw-w-full tw-rounded-lg tw-py-4 tw-text-lg tw-font-semibold"
                                            color="primary"
                                            >{{ $t('setup.config') }}</v-btn
                                        >
                                    </v-form>
                                </div>
                            </v-card-item>
                        </v-card>
                    </div>
                    <div v-else>
                        <v-card
                            class="mx-auto my-8"
                            max-width="600">
                            <v-card-item>
                                <div class="tw-flex tw-flex-col tw-items-center">
                                    <v-icon class="tw-text-green-500 tw-text-6xl">mdi-check-circle</v-icon>
                                    <h1 class="tw-mt-4 tw-text-2xl tw-font-semibold">{{ $t('setup.label2') }}</h1>
                                    <!-- add data of location -->
                                    <p class="tw-mt-4 tw-text-center tw-text-lg">
                                        {{ location?.name }}
                                    </p>

                                    <v-divider class="tw-mt-4"></v-divider>

                                    <v-btn
                                        @click="reconfig"
                                        class="tw-bg-primary-500 tw-text-white tw-mx-auto tw-my-4 tw-h-auto tw-w-full tw-rounded-lg tw-py-4 tw-text-lg tw-font-semibold"
                                        color="primary"
                                        variant="outlined"
                                        >{{ $t('setup.reconfig') }}</v-btn
                                    >
                                </div>
                            </v-card-item>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import useRules from '@/composables/rules';
    import { ref, computed } from 'vue';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { Device } from '@capacitor/device';
    import axios from '@/plugins/axios';
    import { useSetupStore } from '@/store/setup';
    import { useAlert } from '@/composables/useAlert';
    import { ContentType } from '@/contentTypes';
    import _ from 'lodash';
    import { useI18n } from 'vue-i18n';

    const rules = useRules();
    const form = ref<VForm>();
    const code = ref('');
    const setupStore = useSetupStore();
    const location = ref<ContentType<'Location'> | null>();
    location.value = setupStore.getLocation;
    const $t = useI18n().t;

    const { showAlert } = useAlert();

    const isConfigured = ref(setupStore.isConfigured);

    const isDisabled = computed(() => {
        return [!code.value].some((v) => v);
    });

    function reconfig() {
        isConfigured.value = false;
        code.value = '';
    }

    async function onSubmit() {
        const id = await Device.getId();

        try {
            const response = await axios.post('assets/setup', {
                installationCode: code.value,
                deviceId: id.identifier,
            });
            setupStore.setInstallationCode(code.value);
            setupStore.setLocation(response.data.location);
            location.value = response.data.location;
            showAlert({
                color: 'green',
                text: $t('setup.alerts.success'),
                variant: 'flat',
            });
            isConfigured.value = true;
        } catch (error) {
            console.log(error);
            showAlert({
                color: 'red',
                text: $t('setup.alerts.error'),
                variant: 'flat',
            });
        }
    }
</script>

<style scoped></style>
