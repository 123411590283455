<template>
    <v-app>
        <router-view />
        <v-overlay
            class="tw-flex tw-items-center tw-justify-center"
            location-strategy="connected"
            scroll-strategy="reposition"
            :model-value="isLoading">
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"></v-progress-circular>
        </v-overlay>
    </v-app>
</template>

<script setup lang="ts">
    import { useAuth } from '@/composables/useAuth';
    import { useLoader } from '@/composables/loader';
    import { computed } from 'vue';

    useAuth().checkAuth();

    const { getLoader } = useLoader();
    const isLoading = computed(() => getLoader.value);
</script>
