<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-btn
                    @click="MLKITCamera"
                    color="primary"
                    size="x-large"
                    dark
                    >Camera NEW Plugin</v-btn
                >
            </v-col>
            <!-- <v-col cols="12">
                <v-btn
                    @click="MLKITCameraFRONT"
                    color="primary"
                    size="x-large"
                    dark
                    >MLKIT CAMERA FRONT</v-btn
                >
            </v-col> -->
        </v-row>
        <!-- <v-row>
            <v-col cols="12">
                <v-btn
                    @click="MLKITCamera"
                    color="primary"
                    size="x-large"
                    dark
                    >MLKIT CAMERA BACK</v-btn
                >
            </v-col>
        </v-row> -->
<!--
        <div id="test-area-qr-code-webcam">
            <video
                style="width: 100%; height: 100%;"
                autoplay
                playsinline
                muted
                id="video"
            ></video>
        </div> -->
    </v-container>
</template>

<script setup lang="ts">
    import { useQRCodeReader } from '@/composables/useQRCodeReader';
    async function MLKITCamera() {
        useQRCodeReader().scan().then((result) => {
            console.log('result', result);
        }).catch((error) => {
            console.log('error', error);
        });
    }

</script>

<style scoped></style>
