export const ItemPlanStatus = {
    waiting: {
        text: 'itemPlan.status.waiting',
        color: 'warning',
    },
    executing: {
        text: 'itemPlan.status.executing',
        color: 'primary',
    },
    paused: {
        text: 'itemPlan.status.paused',
        color: 'red',
    },
    finished: {
        text: 'itemPlan.status.finished',
        color: 'grey',
    },
};


export const ProductionStatus = {
    executing: {
        text: 'production.status.executing',
        color: 'primary',
    },
    paused: {
        text: 'production.status.paused',
        color: 'red',
    },
    finished: {
        text: 'production.status.finished',
        color: 'grey',
    },
};
