<template>
    <div>
        <v-container>
            <v-row>
                <v-col
                    cols="12"
                    class="tw-flex tw-flex-wrap tw-items-center tw-gap-4">
                    <v-btn
                        @click="$router.go(-1)"
                        color="gray-300"
                        class="tw-rounded tw-text-xl"
                        size="x-large"
                        icon="mdi-chevron-left">
                    </v-btn>
                    <h1 class="tw-text-2xl tw-font-semibold">{{ location.name }}</h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <h2 class="tw-text-center tw-text-xl tw-font-semibold">{{ $t('location.sectionsLabel') }}</h2>
                </v-col>
            </v-row>
            <v-row v-if="location?.children?.length ?? 0 > 0">
                <v-col
                    v-for="(location_, index) in location.children"
                    cols="6"
                    sm="6"
                    md="4"
                    lg="3">
                    <v-card
                        :to="{ name: 'LocationDetail', params: { id: location_.id } }"
                        height="150"
                        variant="tonal"
                        class="tw-flex tw-items-center">
                        <v-card-text class="tw-flex tw-justify-center">
                            <p class="tw-text-lg">{{ location_.name }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <div v-else>
                <p>{{ $t('location.noSections') }}</p>
            </div>

            <v-divider class="my-7"></v-divider>

            <v-row>
                <v-col cols="12">
                    <h2 class="tw-text-center tw-text-xl tw-font-semibold">{{ $t('location.productionOrdersLabel') }}</h2>
                </v-col>
            </v-row>
            <v-row v-if="(location?.item_plans?.length ?? 0) > 0">
                <v-col
                    v-for="(item_plan, index) in location.item_plans"
                    cols="12"
                    md="4">
                    <v-card
                        :to="{ name: 'ItemPlanDetail', params: { id: item_plan.id } }"
                        height="150"
                        class="tw-flex tw-items-center">
                        <v-card-text class="tw-flex-col tw-justify-center">
                            <v-chip
                                :color="ItemPlanStatus[item_plan.status].color"
                                v-if="item_plan.status">
                                {{ $t(ItemPlanStatus[item_plan.status].text) }}
                            </v-chip>
                            <p class="tw-text-center tw-text-lg tw-font-semibold">{{ item_plan?.production_plan?.batch?.production_order?.code }}</p>
                            <p class="tw-text-center tw-text-base">{{ item_plan.operation }}</p>

                            <p class="tw-text-center">{{ $t('location.sequence') }}: {{ item_plan?.sequence }}</p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <div v-else>
                <p>{{ $t('location.noProductionOrders') }}</p>
            </div>
        </v-container>
    </div>
</template>

<script setup lang="ts">
    import axios from '@/plugins/axios';
    import { ContentType } from '@/contentTypes';
    import qs from 'qs';
    import _ from 'lodash';
    import { ref, watch } from 'vue';
    import { useSetupStore } from '@/store/setup';
    import { useRoute } from 'vue-router';
    import { useRouter } from 'vue-router';
    import { ItemPlanStatus } from '@/config/config';
    import { useLoader } from '@/composables/loader';
    import { useI18n } from 'vue-i18n';

    const setup_store = useSetupStore();

    const location = ref<ContentType<'Location'>>({});
    const $route = useRoute();
    const $router = useRouter();
    const { showLoader, hideLoader } = useLoader();
    const $t = useI18n().t;

    async function getLocation() {
        showLoader();
        try {
            let query = qs.stringify({
                populate: ['children', 'assets', 'children.assets', 'item_plans.production_plan.batch.production_order'],
            });

            const response = await axios.get(`locations/${$route.params.id}?${query}`);
            location.value = response.data.data;
        } catch (error) {
            console.log(error);
        } finally {
            hideLoader();
        }
    }

    getLocation();

    watch(
        () => $route.params.id,
        () => {
            getLocation();
        },
    );
</script>

<style scoped></style>
