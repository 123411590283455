// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { useSetupStore } from '@/store/setup';

import Home from '@/views/Home.vue';

import Dashboard from '@/views/Home/Dashboard.vue';
import Setup from '@/views/Setup/Setup.vue';
import Production from '@/views/Production/Production.vue';

import Location from '@/views/Location/Location.vue';
import LocationDetail from '@/views/Location/LocationDetail.vue';

import ItemPlan from '@/views/ItemPlan/ItemPlan.vue';
import ItemPlanDetail from '@/views/ItemPlan/ItemPlanDetail.vue';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';




const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Home' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password/:token',
                name: 'ResetPassword',
                component: ResetPassword,
            },
        ],
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/',
        component: Home,
        name: 'Home',
        redirect: { name: 'Dashboard' },
        children: [
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: {
                    configRequired: true,
                    authRequired: false,
                },
            },
            {
                path: 'setup',
                component: Setup,
                name: 'Setup',
                meta: {
                    configRequired: false,
                    authRequired: false,
                },
            },
            {
                path: 'location',
                component: Location,
                //name: 'Location',
                meta: {
                    configRequired: true,
                    authRequired: false,
                },
                children: [
                    {
                        path: ':id',
                        component: LocationDetail,
                        name: 'LocationDetail',
                    },
                ],
            },
            {
                path: 'item-plan',
                component: ItemPlan,
                //name: 'ItemPlan',
                meta: {
                    configRequired: true,
                    authRequired: false,
                },
                children: [
                    {
                        path: ':id',
                        component: ItemPlanDetail,
                        name: 'ItemPlanDetail',
                    },
                ],
            },
            {
                path: 'production',
                //component: Production,
                name: 'Production',
                meta: {
                    configRequired: true,
                    authRequired: false,
                },
                children: [
                    {
                        path: ':id',
                        component: Production,
                        name: 'ProductionDetail',
                    },
                ],
            },
        ],
    },

    {},
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    console.log('to', to);
    // console.log('from', from);
    const configRequired = to.matched.some((route) => route.meta.configRequired);
    const authRequired = to.matched.some((route) => route.meta.authRequired);

    console.log('configRequired', configRequired);

    if (configRequired) {
        const setupStore = useSetupStore();
        console.log(!setupStore.getInstallationCode);
        if (!setupStore.getInstallationCode) {
            next({ name: 'Setup' });
        } else {
            if (authRequired) {
                const authStore = useAuthStore();
                if (!authStore.isAuthenticated) {
                    next({ name: 'Login' });
                } else {
                    next();
                }
            } else {
                next();
            }
        }
    } else {
        if (authRequired) {
            const authStore = useAuthStore();
            if (!authStore.isAuthenticated) {
                next({ name: 'Login' });
            } else {
                next();
            }
        } else {
            next();
        }
    }

    // if (authRequired) {
    //     const authStore = useAuthStore();
    //     if (!authStore.isAuthenticated) {
    //         next({ name: 'Login' });
    //     } else {
    //         next();
    //     }
    // } else {
    //     next();
    // }
});

export default router;
