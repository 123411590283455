<template>
    <div>
        <v-dialog
            max-width="800"
            v-model="showDialg">
            <!-- add close -->
            <div class="tw-flex tw-justify-end">
                <v-btn
                    @click="emit('close')"
                    class="tw-m-4"
                    icon
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </div>

            <v-card class="tw-flex tw-items-center tw-justify-center tw-pt-12">
                <v-form
                    ref="form"
                    @submit.prevent="onSubmit"
                    class="general-form">
                    <!-- add text field number to set quantity produced-->
                    <v-text-field
                        type="number"
                        v-model="quantityProduced"
                        :rules="[rules.required]"
                        class="form-input"
                        dense
                        :placeholder="$t('production.finishModal.quantityLabel')"></v-text-field>

                    <div v-if="errorMessage">
                        <v-alert
                            type="error"
                            variant="tonal"
                            density="compact"
                            >{{ errorMessage }}</v-alert
                        >
                    </div>

                    <v-btn
                        :loading="isLoading"
                        type="submit"
                        color="primary"
                        class="submit-btn"
                        >{{ $t('production.finishModal.finishBtn') }}</v-btn
                    >
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup lang="ts">
    import { defineProps, computed, ref } from 'vue';
    import useRules from '@/composables/rules';
    import axios from '@axios';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useError } from '@/composables/useError';

    const $error = useError();

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);
    const errorMessage = ref('');
    const isFinished = ref(false);
    const quantityProduced = ref('');

    const props = defineProps({
        dialog: Boolean,
    });

    const emit = defineEmits(['close', 'finish']);

    const showDialg = computed({
        get: () => props.dialog,
        set: (value) => {
            if (value) {
                return value;
            }
        },
    });

    async function onSubmit() {
        if (!form.value) return;

        errorMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;
        emit('finish', { isFinished: isFinished.value, quantityProduced: quantityProduced.value });
        form.value?.reset();
    }
</script>

<style scoped></style>
