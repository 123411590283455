<template>
    <div>
        <v-dialog
            max-width="800"
            v-model="showDialg">
            <!-- add close -->
            <div class="tw-flex tw-justify-end">
                <v-btn
                    @click="emit('close')"
                    class="tw-m-4"
                    icon
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </div>

            <v-card class="tw-flex tw-items-center tw-justify-center tw-pt-12">
                <v-form
                    ref="form"
                    @submit.prevent="onSubmit"
                    class="general-form">
                    <v-select
                        v-model="description"
                        :items="stop_reasons"
                        item-title="reason"
                        item-value="id"
                        :rules="[rules.required]"
                        class="form-input"
                        :label="$t('production.stopModal.reasonLabel')"></v-select>

                    <div v-if="errorMessage">
                        <v-alert
                            type="error"
                            variant="tonal"
                            density="compact"
                            >{{ errorMessage }}</v-alert
                        >
                    </div>

                    <v-btn
                        :loading="isLoading"
                        type="submit"
                        color="primary"
                        class="submit-btn"
                        >{{ $t('production.stopModal.stopBtn') }}</v-btn
                    >
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup lang="ts">
    import { defineProps, computed, ref } from 'vue';
    import useRules from '@/composables/rules';
    import axios from '@axios';
    import { VForm } from 'vuetify/lib/components/index.mjs';
    import { useAuthStore } from '@/store/auth';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useError } from '@/composables/useError';
    import { ContentType } from '@/contentTypes';

    const $error = useError();

    const rules = useRules();
    const form = ref<VForm>();
    const isLoading = ref(false);
    const errorMessage = ref('');
    const description = ref('');
    const stop_reasons = ref<ContentType<'StopReason'>[]>([]);

    const getStopReasons = async () => {
        try {
            const result = await axios.get('/stop-reasons');
            stop_reasons.value = result.data?.data
            console.log(stop_reasons.value);
        } catch (error) {
            console.log(error);
        }
    };

    const props = defineProps({
        dialog: Boolean,
    });

    const emit = defineEmits(['close', 'stop']);

    const showDialg = computed({
        get: () => props.dialog,
        set: (value) => {
            if (value) {
                return value;
            }
        },
    });

    async function onSubmit() {
        if (!form.value) return;

        errorMessage.value = '';
        form.value.resetValidation();

        const { valid } = await form.value.validate();
        if (!valid) return;

        emit('stop', { stopReason: description.value });
        form.value?.reset();
    }

    getStopReasons();
</script>

<style scoped></style>
