import { ContentType } from '@/contentTypes';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: '' as string,
        user:null as ContentType<'User'> | null,
    }),
    getters: {
        isAuthenticated(): boolean {
            return !!this.token;
        },
        getToken(): string {
            return this.token;
        },
        getUser(): any {
            return this.user;
        },
    },
    actions: {
        setToken(token: string) {
            this.token = token;
        },
        setUser(user: any) {
            this.user = user;
        },
        resetStore() {
            this.token = '';
            this.user = null;
        }
    },
    persist: true,
});
