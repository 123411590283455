<template>
    <v-container>
        <v-row v-if="production">
            <v-col
                cols="12"
                class="tw-flex tw-flex-wrap tw-items-center tw-gap-4">
                <v-btn
                    @click="$router.go(-1)"
                    color="gray-300"
                    class="tw-rounded tw-text-xl"
                    size="x-large"
                    icon="mdi-chevron-left">
                </v-btn>
                <h1 class="tw-text-2xl tw-font-semibold">
                    {{ $t('production.productionLabel') }} - {{ production?.item_plan?.production_plan?.batch?.production_order?.code }} - {{ production?.asset?.name }}
                </h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                md="9">
                <div class="tw-flex tw-gap-3">
                    <p class="tw-text-lg tw-font-semibold">{{ $t('production.statusLabel') }}:</p>
                    <v-chip
                        :color="ProductionStatus[production.status].color"
                        v-if="production?.status">
                        {{ $t(ProductionStatus[production.status].text) }}
                    </v-chip>
                </div>
                <p
                    v-if="production?.createdAt"
                    class="tw-text-lg tw-font-semibold">
                    {{ $t('production.initDateLabel') }}: <span class="tw-font-normal">{{ production?.createdAt }}</span>
                </p>
                <p
                    v-if="production?.timer"
                    class="tw-text-lg tw-font-semibold">
                    {{ $t('production.timerLabel') }}: <span class="tw-font-normal">{{ production?.timer }}</span>
                </p>
                <p
                    v-if="production?.end"
                    class="tw-text-lg tw-font-semibold">
                    {{ $t('production.endDateLabel') }}: <span class="tw-font-normal">{{ production?.end }}</span>
                </p>
                <p
                    v-if="production?.quantityProduced"
                    class="tw-text-lg tw-font-semibold">
                    {{ $t('production.quantityLabel') }}: <span class="tw-font-normal">{{ production?.quantityProduced }}</span>
                </p>
            </v-col>
            <v-col
                cols="12"
                md="3">
                <div>
                    <v-btn
                        v-if="!isAuthenticated"
                        color="white"
                        @click="isOpenLoginModal = true"
                        class="tw-mb-3"
                        height="100"
                        block
                        size="x-large">
                        {{ $t('production.btn.auth') }}
                    </v-btn>
                    <v-btn
                        v-if="production?.status === 'executing'"
                        color="primary"
                        class="tw-mb-3"
                        height="100"
                        block
                        :disabled="!isAuthenticated"
                        @click="isOpenStopProductionModal = true"
                        size="x-large">
                        {{ $t('production.btn.pause') }}
                    </v-btn>
                    <v-btn
                        v-if="production?.status === 'paused'"
                        color="primary"
                        class="tw-mb-3"
                        height="100"
                        block
                        :disabled="!isAuthenticated"
                        @click="continueProduction"
                        size="x-large">
                        {{ $t('production.btn.resume') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        class="tw-mb-12"
                        height="100"
                        v-if="production?.status === 'executing' || production?.status === 'paused'"
                        block
                        @click="isOpenFinishProductionModal = true"
                        :disabled="!isAuthenticated"
                        size="x-large">
                        {{ $t('production.btn.finish') }}
                    </v-btn>
                </div>
            </v-col>
        </v-row>
    </v-container>

    <LoginModal
        @close="isOpenLoginModal = false"
        :dialog="isOpenLoginModal" />

    <StopProductionModal
        @close="isOpenStopProductionModal = false"
        :dialog="isOpenStopProductionModal"
        @stop="stopProduction" />

    <FinishProductionModal
        :dialog="isOpenFinishProductionModal"
        @close="isOpenFinishProductionModal = false"
        @finish="finishProduction" />
</template>

<script setup lang="ts">
    import { ContentType } from '@/contentTypes';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import _ from 'lodash';
    import { ref, computed, onMounted, createApp } from 'vue';
    import { useSetupStore } from '@/store/setup';
    import { useRoute } from 'vue-router';
    import { ProductionStatus } from '@/config/config';
    import { useAuthStore } from '@/store/auth';
    import { parseDate, parseTime } from '@/composables/date';
    import LoginModal from '@/views/Auth/LoginModal.vue';
    import StopProductionModal from '@/views/Production/StopProductionModal.vue';
    import FinishProductionModal from '@/views/Production/FinishProductionModal.vue';
    import { useAlert } from '@/composables/useAlert';
    import { useI18n } from 'vue-i18n';

    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const production = ref<ContentType<'Production'>>();
    const route = useRoute();
    const id = route.params.id;
    const isOpenLoginModal = ref(false);
    const isOpenStopProductionModal = ref(false);
    const isOpenFinishProductionModal = ref(false);
    const { showAlert } = useAlert();
    const $t = useI18n().t;

    async function finishProduction({ isFinished, quantityProduced }: { isFinished: boolean; quantityProduced: string }) {
        isOpenFinishProductionModal.value = false;

        try {
            const { data } = await axios.post(`/productions/finish`, {
                productionId: id,
                isFinished: isFinished,
                quantityProduced: quantityProduced,
            });

            production.value = data;
            showAlert({
                color: 'green',
                text: $t('production.finishModal.alerts.success'),
                variant: 'flat',
            });
        } catch (error) {
            console.error(error);
            showAlert({
                color: 'green',
                text: $t('production.finishModal.alerts.error'),
                variant: 'flat',
            });
        }
    }

    async function stopProduction({ stopReason }: { stopReason: string }) {
        try {
            const { data } = await axios.post(`/stops/init`, {
                productionId: id,
                stop_reason: stopReason,
            });

            production.value = data;
            console.log(production.value);

            showAlert({
                color: 'green',
                text: $t('production.stopModal.alerts.pause.success'),
                variant: 'flat',
            });
        } catch (error) {
            showAlert({
                color: 'red',
                text: $t('production.stopModal.alerts.pause.error'),
                variant: 'flat',
            });
            console.error(error);
        } finally {
            isOpenStopProductionModal.value = false;
        }
    }

    async function continueProduction() {
        try {
            const { data } = await axios.post(`/stops/finish`, {
                productionId: id,
            });
            production.value = data;

            showAlert({
                color: 'green',
                text: $t('production.stopModal.alerts.resume.success'),
                variant: 'flat',
            });
        } catch (error) {
            console.error(error);
            showAlert({
                color: 'red',
                text: $t('production.stopModal.alerts.resume.error'),
                variant: 'flat',
            });
        }
    }

    const updateTimer = (production: ContentType<'Production'>) => {
        let now, createdAt, timeDifference;

        if (production.value.status === 'finished') {
            now = new Date(production.value.end ?? 0).getTime();
            createdAt = new Date(production.value.createdAt ?? 0).getTime();
            timeDifference = now - createdAt;
        } else {
            now = new Date().getTime();
            createdAt = new Date(production.value.createdAt ?? 0).getTime();
            timeDifference = now - createdAt;
        }

        // // is production has stops remove the time of the stops
        if (production.value.stops?.length ?? 0 > 0) {
            production.value.stops.forEach((stop) => {
                // if stop.end is null, production is stopped and we need to remove the time of the stop
                if (stop.end == null) {
                    let start = new Date(stop.createdAt ?? 0).getTime();
                    timeDifference -= now - start;
                } else {
                    let start = new Date(stop.createdAt ?? 0).getTime();
                    let end = new Date(stop.end ?? 0).getTime();
                    timeDifference -= end - start;
                }
            });
        }

        let hours = Math.floor(timeDifference / (1000 * 60 * 60));
        let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        if (seconds < 10) seconds = `0${seconds}`;

        return `${hours}:${minutes}:${seconds}`;
    };

    onMounted(() => {
        setInterval(() => {
            production.value.timer = updateTimer(production);
        }, 1000);
    });

    const getProduction = async () => {
        try {
            let query = qs.stringify({
                populate: ['item_plan.production_plan.batch.production_order', 'asset', 'user', 'stops'],
            });
            const { data } = await axios.get(`/productions/${id}?${query}`);

            production.value = data.data;
        } catch (error) {
            console.error(error);
        }
    };

    getProduction();
</script>

<style scoped></style>
