import { BarcodeScanner as MLKITBarcodeScanner, BarcodeFormat, LensFacing } from '@capacitor-mlkit/barcode-scanning';
import { BrowserQRCodeReader, BrowserCodeReader } from '@zxing/browser';
import { Capacitor } from '@capacitor/core';

export const useQRCodeReader = () => {
    const isCapacitor = Capacitor.isNativePlatform();
    const isBrowser = !isCapacitor;

    const scan = async () => {
        if (isCapacitor) {
            return scanCapacitor();
        } else if (isBrowser) {
            return scanBrowser();
        }
    };

    const scanCapacitor = async () => {
        return new Promise(async (resolve) => {
            document.querySelector('body')?.classList.add('barcode-scanner-active');

            const listener = await MLKITBarcodeScanner.addListener('barcodeScanned', async (result) => {
                console.log('result', result);
                await listener.remove();
                document.querySelector('body')?.classList.remove('barcode-scanner-active');
                await MLKITBarcodeScanner.stopScan();
                resolve(result.barcode.rawValue);
            });

            await MLKITBarcodeScanner.startScan({
                formats: [BarcodeFormat.QrCode],
                lensFacing: LensFacing.Back,
            });
        });
    };

    const scanBrowser = async () => {
        return new Promise(async (resolve) => {

            const codeReader = new BrowserQRCodeReader();

            // add div to the DOM
            const divElem = document.createElement('div');
            divElem.setAttribute('id', 'test-area-qr-code-webcam');
            //divElem.setAttribute('style', 'width: 100%; height: 100%; position: absolute; top: 0; left: 0; z-index: 9999;');
            divElem.setAttribute('style', 'width: 100%; height: 100%; position: absolute; top: 0; left: 0;');
            document.querySelector('main')?.appendChild(divElem);

            // add the video element to the DOM
            const videoElem = document.createElement('video');
            videoElem.setAttribute('autoplay', 'true');
            videoElem.setAttribute('playsinline', 'true');
            videoElem.setAttribute('muted', 'true');
            videoElem.setAttribute('id', 'video');
            videoElem.setAttribute('style', 'width: 100%; height: 100%;');
            divElem.appendChild(videoElem);

            const videoInputDevices = await BrowserCodeReader.listVideoInputDevices();

            // choose your media device (webcam, frontal camera, back camera, etc.)
            const selectedDeviceId = videoInputDevices[0].deviceId;

            console.log(`Started decode from camera with id ${selectedDeviceId}`);

            const previewElem = document.querySelector('#test-area-qr-code-webcam > video');

            // you can use the controls to stop() the scan or switchTorch() if available
            const controls = await codeReader.decodeFromVideoDevice(undefined, previewElem, (result, error, controls) => {
                // use the result and error values to choose your actions
                // you can also use controls API in this scope like the controls
                // returned from the method.

                if (result) {
                    controls.stop();
                    // remove the video and div elements from the DOM
                    document.querySelector('#test-area-qr-code-webcam')?.remove();
                    resolve(result.getText());
                }
            });
        })
    };

    return {
        scan,
    };
};
