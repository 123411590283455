<template>
    <v-app-bar
        height="80"
        :elevation="2"
        color="primary">
        <template v-slot:prepend>
            <v-app-bar-title>Application Bar</v-app-bar-title>
        </template>
        <template v-slot:append>
            <v-btn
                v-if="isAuthenticated"
                prepend-icon="mdi-logout"
                size="x-large"
                variant="outlined"
                @click="logout"
                >{{ $t('navbar.logout') }}</v-btn
            >
        </template>
    </v-app-bar>
    <v-bottom-navigation
        height="100"
        bg-color="primary">
        <!-- add home btn-->
        <v-btn
            size="x-large"
            :to="{ name: 'Dashboard' }">
            <v-icon size="x-large"> mdi-home</v-icon>

            <span size="x-large">{{ $t('navbar.home') }}</span>
        </v-btn>
        <v-btn
            size="x-large"
            :to="{ name: 'Setup' }">
            <v-icon size="x-large"> mdi-cellphone-cog</v-icon>

            <span>{{ $t('navbar.config') }}</span>
        </v-btn>

        <v-btn
            size="x-large"
            v-if="setup_store.getLocation.id"
            :to="{ name: 'LocationDetail', params: { id: setup_store.getLocation.id } }">
            <v-icon size="x-large"> mdi-factory</v-icon>

            <span>{{ $t('navbar.production') }}</span>
        </v-btn>
    </v-bottom-navigation>
    <v-main>
        <ioAlert />
        <!-- <v-btn @click="isSidebarOpen = !isSidebarOpen" class="tw-z-10" position="fixed" :location="'left top'" icon="mdi-menu" rounded=""></v-btn> -->
        <router-view />
    </v-main>
</template>

<script lang="ts" setup>
    import Sidebar from './Sidebar.vue';
    import Navbar from './Navbar.vue';
    import ioAlert from '@/components/ioAlert.vue';
    import { useAuthStore } from '@/store/auth';
    import { computed } from 'vue';
    import { useRouter } from 'vue-router';
    import { useSetupStore } from '@/store/setup';

    const $router = useRouter();
    const setup_store = useSetupStore();
    const isAuthenticated = computed(() => {
        return useAuthStore().isAuthenticated;
    });

    function logout() {
        useAuthStore().resetStore();
        //$router.push({ name: 'Dashboard' });
    }
</script>
