import { ContentType } from '@/contentTypes';
import { defineStore } from 'pinia';

export const useSetupStore = defineStore('setup', {
    state: () => ({
        installation_code: '' as string,
        location: {} as ContentType<'Location'>,
    }),
    getters: {
        getInstallationCode(): string {
            return this.installation_code;
        },
        getLocation(): ContentType<'Location'> {
            return this.location;
        },
        isConfigured(): boolean {
            return this.installation_code !== '' && Object.keys(this.location).length > 0;
        }
    },
    actions: {
        setInstallationCode(installation_code: string) {
            this.installation_code = installation_code;
        },
        setLocation(location: ContentType<'Location'>) {
            this.location = location;
        },
    },
    persist: true,
});
