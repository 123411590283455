<template>
    <div>
        <v-container>
            <v-row v-if="itemPlan">
                <v-col
                    cols="12"
                    class="tw-flex tw-flex-wrap tw-items-center tw-gap-4">
                    <v-btn
                        @click="$router.go(-1)"
                        color="gray-300"
                        class="tw-rounded tw-text-xl"
                        size="x-large"
                        icon="mdi-chevron-left">
                    </v-btn>
                    <h1 class="tw-text-2xl tw-font-semibold">
                        {{ itemPlan?.production_plan?.batch?.production_order?.code ? itemPlan.production_plan.batch.production_order.code : '' }}
                    </h1>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                    cols="12"
                    md="9">
                    <div class="tw-flex tw-gap-3">
                        <p class="tw-text-lg tw-font-semibold">{{ $t('itemPlan.statusLabel') }}:</p>
                        <v-chip
                            :color="ItemPlanStatus[itemPlan.status].color"
                            v-if="itemPlan.status">
                            {{ $t(ItemPlanStatus[itemPlan.status].text) }}
                        </v-chip>
                    </div>
                    <p class="tw-text-lg tw-font-semibold">
                        {{ $t('itemPlan.operationLabel') }}: <span class="tw-font-normal">{{ itemPlan.operation }}</span>
                    </p>
                    <p class="tw-text-lg tw-font-semibold">
                        {{ $t('itemPlan.sequenceLabel') }}: <span class="tw-font-normal">{{ itemPlan.sequence }}</span>
                    </p>

                    <v-row class="tw-mt-2">
                        <v-col cols="12">
                            <p class="tw-text-center tw-text-lg tw-font-bold">{{ $t('itemPlan.orderInfo') }}</p>
                        </v-col>
                        <v-col
                            md="6"
                            cols="12">
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.number">
                                {{ $t('itemPlan.orderNumber') }}: <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.number }}</span>
                            </p>
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.yarn">
                                <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.yarn }}</span>
                            </p>
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.yarn2">
                                <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.yarn2 }}</span>
                            </p>
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.NE">
                                {{ $t('itemPlan.NE') }}: <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.NE }}</span>
                            </p>
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.type">
                                {{ $t('itemPlan.type') }}: <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.type }}</span>
                            </p>
                        </v-col>
                        <v-col
                            md="6"
                            cols="12">
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.type2">
                                <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.type2 }}</span>
                            </p>
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.composition">
                                {{ $t('itemPlan.composition') }}: <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.composition }}</span>
                            </p>
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.numCables">
                                {{ $t('itemPlan.numCables') }}: <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.numCables }}</span>
                            </p>
                            <p
                                class="tw-text-lg tw-font-semibold"
                                v-if="itemPlan.production_plan?.batch?.twist">
                                <span class="tw-font-normal">{{ itemPlan.production_plan?.batch?.twist }}</span>
                            </p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    cols="12"
                    md="3"
                    v-if="itemPlan.status !== 'finished'">
                    <v-btn
                        color="white"
                        @click="login"
                        class="tw-mb-3"
                        v-if="!isAuthenticated"
                        height="100"
                        block
                        size="x-large">
                        {{ $t('itemPlan.btn.auth') }}
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="disabledOperationEnd"
                        @click="endOperation"
                        class="tw-mb-3"
                        height="100"
                        block
                        size="x-large">
                        {{ $t('itemPlan.btn.endOperation') }}
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="tw-mb-6 tw-mt-6"></v-divider>
            <v-row v-if="itemPlan.status != 'finished'">
                <v-col
                    v-if="!isAuthenticated"
                    cols="12"
                    md="3">
                    <v-btn
                        color="white"
                        @click="login"
                        class="tw-mb-3"
                        height="100"
                        block
                        size="x-large">
                        {{ $t('itemPlan.btn.auth') }}
                    </v-btn>
                </v-col>
                <v-col
                    cols="12"
                    md="3">
                    <v-btn
                        color="white"
                        class="tw-mb-3"
                        height="100"
                        :disabled="!isAuthenticated"
                        @click="checkOrderProdution"
                        :prepend-icon="orderProdutionChecked ? 'mdi-check-circle' : 'mdi-alert-circle'"
                        block
                        size="x-large">
                        <template v-slot:prepend>
                            <v-icon :color="orderProdutionChecked ? 'success' : 'warning'"></v-icon>
                        </template>
                        {{ $t('itemPlan.btn.checkOrder') }}
                    </v-btn></v-col
                >
                <v-col
                    cols="12"
                    md="3">
                    <v-btn
                        color="white"
                        height="100"
                        :disabled="!isAuthenticated"
                        @click="associateMachine"
                        :prepend-icon="machineAssociated ? 'mdi-check-circle' : 'mdi-alert-circle'"
                        block
                        size="x-large">
                        <template v-slot:prepend>
                            <v-icon :color="machineAssociated ? 'success' : 'warning'"></v-icon>
                        </template>
                        {{ $t('itemPlan.btn.setMachine') }}
                    </v-btn>
                </v-col>
                <v-col
                    cols="12"
                    md="3">
                    <v-switch
                        v-model="isSectionedMachine"
                        :label="`É uma máquina seccionada?`"
                        color="primary"
                        hide-details
                        inset></v-switch>
                    <v-text-field
                        class="form-input"
                        label="Numéro de secções a utilizar"
                        :disabled="!isSectionedMachine"
                        v-model="numberOfSections"
                        placeholder="Numéro de secções a utilizar"
                        type="number"></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="3">
                    <v-btn
                        :disabled="disabledSubmit"
                        size="x-large"
                        class="tw-font-semibold"
                        height="100"
                        @click="initProduction"
                        block
                        color="primary">
                        {{ $t('itemPlan.btn.startProduction') }}</v-btn
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <h1 class="tw-text-2xl tw-font-semibold">{{ $t('itemPlan.productionList') }}</h1>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-table :hover="true">
                        <thead>
                            <tr>
                                <th class="text-left">{{ $t('itemPlan.table.headers.machine') }}</th>
                                <th class="text-left">{{ $t('itemPlan.table.headers.initDate') }}</th>
                                <th class="text-left">{{ $t('itemPlan.table.headers.endDate') }}</th>
                                <th class="text-left">{{ $t('itemPlan.table.headers.user') }}</th>
                                <th class="text-left">{{ $t('itemPlan.table.headers.status') }}</th>
                                <th class="text-left">{{ $t('itemPlan.table.headers.time') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="tw-cursor-pointer"
                                @click="goToProduction(production?.id)"
                                v-for="production in productions"
                                :key="production.id">
                                <td>{{ production?.asset?.name }}</td>
                                <td>{{ production.createdAt }}</td>
                                <td>{{ production.end }}</td>
                                <td>{{ production.user?.username }}</td>
                                <td>
                                    <v-chip
                                        :color="ProductionStatus[production.status].color"
                                        v-if="production.status">
                                        {{ $t(ProductionStatus[production.status].text) }}
                                    </v-chip>
                                </td>
                                <!-- add column created at - now -->
                                <td>
                                    {{ production.timer }}
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
    <LoginModal
        @close="closeLoginModal"
        :dialog="isOpenLoginModal" />
</template>

<script setup lang="ts">
    import axios from '@/plugins/axios';
    import { ContentType } from '@/contentTypes';
    import qs from 'qs';
    import _ from 'lodash';
    import { useRoute } from 'vue-router';
    import { ref, computed, onMounted } from 'vue';
    import { ItemPlanStatus, ProductionStatus } from '@/config/config';
    import { useAlert } from '@/composables/useAlert';
    import { useAuthStore } from '@/store/auth';
    import LoginModal from '@/views/Auth/LoginModal.vue';
    import { useRouter } from 'vue-router';
    import { useLoader } from '@/composables/loader';
    import { useI18n } from 'vue-i18n';
    import { useQRCodeReader } from '@/composables/useQRCodeReader';

    const { showAlert } = useAlert();
    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);
    const itemPlan = ref<ContentType<'ItemPlan'>>({});
    const productions = ref<ContentType<'Production'>[]>([]);
    const $route = useRoute();
    const id = ref($route.params.id);
    const orderProdutionChecked = ref(false);
    const machineAssociated = ref(false);
    const isSectionedMachine = ref(false);
    const numberOfSections = ref();
    const orderProductionCode = ref('');
    const assetCode = ref('');
    const $router = useRouter();
    const { showLoader, hideLoader } = useLoader();
    const $t = useI18n().t;

    const disabledSubmit = computed(() => {
        return !orderProdutionChecked.value || !machineAssociated.value || !isAuthenticated.value || (isSectionedMachine.value && !numberOfSections.value);
    });

    const disabledOperationEnd = computed(() => {
        return !isAuthenticated.value || productions.value.length === 0 || productions.value.some((production) => production.status !== 'finished');
    });

    const isOpenLoginModal = ref(false);

    async function login() {
        isOpenLoginModal.value = true;
    }

    function closeLoginModal() {
        isOpenLoginModal.value = false;
    }

    async function getItemPlan() {
        try {
            let query = qs.stringify({
                populate: ['production_plan.batch.production_order', 'location', 'productions.asset', 'productions.user', 'productions.stops'],
            });

            const response = await axios.get(`item-plans/${id.value}?${query}`);
            itemPlan.value = response.data.data;
            productions.value = response.data.data.productions;
            console.log(itemPlan.value);
        } catch (error) {
            console.log(error);
        }
    }

    async function initProduction() {
        if (disabledSubmit.value) {
            showAlert({
                color: 'red',
                text: 'Ordem de fabrico e máquina não verificadas',
                variant: 'flat',
            });
            return;
        }

        let body = {
            itemPlanId: itemPlan.value.id,
            validationCodeProductionOrder: orderProductionCode.value,
            validationCodeAsset: assetCode.value,
            isSectionedMachine: isSectionedMachine.value,
            numberOfSections: numberOfSections.value,
        };

        try {
            showLoader();
            const response = await axios.post('productions/init', body);
            productions.value.push(response.data.production);

            showAlert({
                color: 'green',
                text: 'Produção iniciada',
                variant: 'flat',
            });

            console.log(response.data.itemPlan);

            itemPlan.value = response.data.itemPlan;
        } catch (error) {
            console.log(error);
            showAlert({
                color: 'red',
                text: 'Erro ao iniciar produção',
                variant: 'flat',
            });
        } finally {
            hideLoader();
        }
    }

    const updateTimer = (production: ContentType<'Production'>) => {
        let now, createdAt, timeDifference;

        if (production.status === 'finished') {
            now = new Date(production.end ?? 0).getTime();
            createdAt = new Date(production.createdAt ?? 0).getTime();
            timeDifference = now - createdAt;
        } else {
            now = new Date().getTime();
            createdAt = new Date(production.createdAt ?? 0).getTime();
            timeDifference = now - createdAt;
        }

        // // is production has stops remove the time of the stops
        if (production.stops?.length ?? 0 > 0) {
            production.stops.forEach((stop) => {
                // if stop.end is null, production is stopped and we need to remove the time of the stop
                if (stop.end == null) {
                    let start = new Date(stop.createdAt ?? 0).getTime();
                    timeDifference -= now - start;
                } else {
                    let start = new Date(stop.createdAt ?? 0).getTime();
                    let end = new Date(stop.end ?? 0).getTime();
                    timeDifference -= end - start;
                }
            });
        }

        let hours = Math.floor(timeDifference / (1000 * 60 * 60));
        let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        if (hours < 10) hours = `0${hours}`;
        if (minutes < 10) minutes = `0${minutes}`;
        if (seconds < 10) seconds = `0${seconds}`;

        return `${hours}:${minutes}:${seconds}`;
    };

    const checkOrderProdution = async () => {
        console.log('Verificar ordem de fabrico');

        useQRCodeReader()
            .scan()
            .then((result) => {
                console.log('result', result);
                if (result === '') {
                    showAlert({
                        color: 'red',
                        text: 'Ordem de fabrico não verificada',
                        variant: 'flat',
                    });
                    return;
                }
                orderProductionCode.value = result as string;
                orderProdutionChecked.value = true;
            })
            .catch((error) => {
                showAlert({
                    color: 'red',
                    text: 'Erro ao ler QRCode, tente novamente',
                    variant: 'flat',
                });
                console.log('error', error);
            });
    };

    const associateMachine = async () => {
        useQRCodeReader()
            .scan()
            .then((result) => {
                console.log('result', result);
                if (result === '') {
                    showAlert({
                        color: 'red',
                        text: 'Ordem de fabrico não verificada',
                        variant: 'flat',
                    });
                    return;
                }
                assetCode.value = result as string;
                machineAssociated.value = true;
            })
            .catch((error) => {
                showAlert({
                    color: 'red',
                    text: 'Erro ao ler QRCode, tente novamente',
                    variant: 'flat',
                });
                console.log('error', error);
            });
    };

    const goToProduction = (id: string) => {
        $router.push({ name: 'ProductionDetail', params: { id } });
    };

    // const getProductions = async () => {
    //     let query = qs.stringify({
    //         populate: ['item_plan', 'asset', 'user', 'stops'],
    //         filters: {
    //             item_plan: {
    //                 id: id.value,
    //             },
    //         },
    //     });

    //     try {
    //         const response = await axios.get(`productions?${query}`);
    //         console.log(response.data);
    //         productions.value = response.data.data;
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const endOperation = async () => {
        try {
            showLoader();
            // put request to end operation
            let query = qs.stringify({
                populate: ['production_plan.batch.production_order', 'location'],
            });
            const response = await axios.put(`item-plans/${id.value}?${query}`, {
                data: {
                    status: 'finished',
                },
            });

            itemPlan.value = response.data.data;
            showAlert({
                color: 'green',
                text: 'Operação finalizada',
                variant: 'flat',
            });
        } catch (error) {
            console.log(error);
            showAlert({
                color: 'red',
                text: 'Erro ao finalizar operação',
                variant: 'flat',
            });
        } finally {
            hideLoader();
        }
    };

    const init = async () => {
        showLoader();
        await getItemPlan();
        //await getProductions();
        hideLoader();
    };

    init();

    onMounted(() => {
        setInterval(() => {
            productions.value.forEach((production) => {
                production.timer = updateTimer(production);
            });
        }, 1000);
    });
</script>

<style scoped></style>
